import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ShareService} from '../../services';
@Component({
  selector: 'app-authentication-login',
  templateUrl: './authentication-login.component.html',
  styleUrls: ['./authentication-login.component.scss'],
})
export class AuthenticationLoginComponent implements OnInit {

  type = 'password';
  login_fail = true;
  @Output() eventEmmiter = new EventEmitter<any>();


  constructor(
      private fb: FormBuilder,
      private shareService: ShareService
  ) { }
  formLogin = this.fb.group({
    phone: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(9)]],
    password: ['', [Validators.required]],
  });
  ngOnInit() {
    const phone = localStorage.getItem('phoneLogin');
    if (phone) {
      this.formLogin.controls.phone.setValue(phone);
    }
  }
  changeTypeInput(type: string) {
    console.log(type);
    if (type === 'password') {
      this.type = 'non_password';
      return;
    }
    this.type = 'password';
  }
  handleLogin() {
    const phone = this.formLogin.controls.phone.value;
    const password = this.formLogin.controls.password.value;
    if (this.formLogin.invalid) {
      if (!phone.trim() || !password.trim()) {
        alert('Chưa nhập đầy đủ thông tin!')
        return;
      }
      alert('Thông tin đăng nhập không hợp lệ!');
      return;
    }
    const body = {
      username: phone,
      password
    }
    this.shareService.loginCore(body).subscribe(
        res => {
          const phone = res.user_data.phone;
          const access_token = res.access_token;
          console.log(phone, access_token);
          localStorage.setItem('access_token', access_token);
          localStorage.setItem('phoneLogin', phone);
          this.eventEmmiter.emit({phone: phone});
        },
        error => {
          alert('Số điện thoại hoặc mật khẩu không chính xác!');
        }
    );
  }
  forgetPassword() {
    console.log('forget password');
    this.eventEmmiter.emit('forget password')
  }
  closeModal() {
    this.eventEmmiter.emit('close');
  }
  redirectRegister() {
    this.eventEmmiter.emit('register');
  }
  errorWhenLogin() {
    window.open('http://quotes.icheck.com.vn/van-de-khi-dang-nhap/');
  }
}
