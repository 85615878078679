import { userIcheck } from 'src/app/helpers/userIcheck';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ShareService} from '../../share/services';
import {OtpComponent, VerifyPhoneComponent} from '../../share';
import {ModalController} from '@ionic/angular';
import {CommonService} from '../../../public/service/common.service';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-my-gift',
    templateUrl: './my-gift.component.html',
    styleUrls: ['./my-gift.component.scss'],
})
export class MyGiftComponent implements OnInit {
    campaignId: any;
    businessId: any;
    business: any;
    deviceId: any;
    phone: any;
    campaign: any;
    isUserIcheck = false;
    countTotal: any;
    gifts: any = [];
    isLoading = false;
    is_change_phone: any;
    offset: number = 0;

    constructor(private route: ActivatedRoute,
                public modalController: ModalController,
                private shareService: ShareService,
                private commonService: CommonService,
                private titleService: Title,
                private router: Router) {
        // get path
        this.campaignId = this.route.snapshot.params.id;
        this.businessId = this.route.snapshot.params.businessId;
        this.is_change_phone = this.route.snapshot.queryParams.is_change_phone;
        this.shareService.deviceId.subscribe(deviceId => this.deviceId = deviceId);
        //get query param
        this.phone = this.route.snapshot.queryParams.phone;
    }

    ngOnInit() {
        this.titleService.setTitle("Kho quà");
        // await this.loadAllGift();
        // await this.loadBusiness();
        // this.isLoading = true;
        Promise.all([
            this.loadBusiness(),
            this.loadCampaign(),
        ]).then(async _ => {
            if (this.is_change_phone === 'false') {
                await this.loadAllGift();
                return;
            }
            if (this.campaign?.campaign_condition?.web_app_condition === 'info_otp_required' && !localStorage.getItem('otpToken')) {
                // cần xác mình sdt khi yêu cầu OTP
                const modal = await this.modalController.create({
                    // component: VerifyPhoneComponent,
                    component: OtpComponent,
                    cssClass: 'popup-confirm-phone',
                    backdropDismiss: false,
                    componentProps: {
                        // campaign_id: this.campaignId,
                        user_id: this.campaign?.owner?.id,
                        phoneDefault: this.phone
                    }
                });
                modal.onDidDismiss().then(async data => {
                    if (data?.data?.phone) {
                        await this.router.navigate([`campaign/${this.campaignId}`], {queryParams: {phone: data?.data?.phone}});
                        await this.loadAllGift();
                    } else if (data?.data?.isSamePhone) {

                    } else {
                        // this.location.back();
                        location.reload();
                        this.isLoading = true;
                    }
                });
                return await modal.present();
            }

            if (this.campaign?.campaign_condition?.web_app_condition === 'info_required' && !this.phone) {
                // cần xác phone khi yêu cầu nhập thông tin tham gia chương trình
                const modal = await this.modalController.create({
                    component: VerifyPhoneComponent,
                    cssClass: 'popup-confirm-phone',
                    backdropDismiss: false,
                    componentProps: {
                        phone: this.phone
                    }
                });
                modal.onDidDismiss().then(async data => {
                    if (data?.data?.phone) {
                        // localStorage.setItem('phone_info_required', data?.data?.phone);
                        this.phone = data?.data?.phone;
                        await this.router.navigate([`campaign/${this.campaignId}/${this.campaign?.owner?.id}/my-gift`], {queryParams: {phone: data?.data?.phone}});
                        await this.loadAllGift();
                    } else {
                        location.reload();
                        // this.location.back();
                        // location.reload();
                        // await this.router.navigate([`campaign/${this.campaignId}`]);
                    }
                    // location.reload();
                });
                return await modal.present();
            }

            await this.loadAllGift();
        });
        // window.addEventListener('scroll', this.onScroll, true);
    }

    loadAllGift() {
        const body = {} as any;
        if (this.phone) {
            body.phone = this.phone;
        }
        body.device_id = this.deviceId;
        body.offset = this.offset || 0;
        body.limit = 10;
        return this.shareService.getAllGift(this.campaignId, body).toPromise().then((res: any) => {
            if (res?.statusCode === 200) {
                this.gifts = this.gifts.concat(res?.data?.rows);
                this.countTotal = res?.data?.count;
                this.isLoading = true;
            }
        });
    }

    loadBusiness() {
        return this.shareService.getBusiness(this.businessId).toPromise().then((res: any) => {
            if (res?.statusCode === 200) {
                this.business = res?.data;
            }
        });
    }

    loadCampaign() {
        return this.shareService.getCampaign(this.campaignId).toPromise().then((res: any) => {
            if (res?.statusCode === 200) {
                this.campaign = res?.data;
                if(userIcheck(res.data)){
                    this.isUserIcheck = true;
                  }
            }
        });
    }

    async onViewCampaign() {
        const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
        await this.router.navigate([`campaign/${this.campaignId}`], {queryParams: {phone: this.phone, business_id: this.businessId, is_change_phone: this.is_change_phone || is_change_phone}});
    }

    async onLanding() {
        await this.router.navigate([`landing/${this.businessId}`], {queryParams: {phone: this.phone}});
    }

    async onGiftDetail(gift) {
        // :campaign_id/:target/:type/:code/:gift_id/:winner_id/:winner_gift_id'
        //     gift-detail/3695/undefined/1/null/1184/17240/17165?phone=0964772195
        // localStorage.setItem('parent_campaign_id', this.campaignId);
        if (moment().isAfter(moment(gift?.campaign?.export_gift_to))) {
            return;
        }
        await this.router.navigate([`gift-detail/${gift?.campaign_id}/undefined/1/null/${gift?.gift_id}/${gift?.winner_id}/${gift?.id}`], {queryParams: {phone: this.phone,  parent_campaign_id: this.campaignId }});
    }

    isShowStatusGift(gift) {
        return moment().isAfter(moment(gift?.campaign?.export_gift_to));
    }

    async onScrollDown() {
        if (this.countTotal >= this.offset) {
            this.offset = this.offset + 10;
            await this.loadAllGift();
        }
    }

    getStatusVoucher(voucher) {
        return this.commonService.getStatusVoucher(voucher);
    }

    getStatusLottery(voucher){
        return this.commonService.getStatusLottery(voucher);
      }

    ngOnDestroy(){
        this.titleService.setTitle("Loyalty");
    }
}
