import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location } from "@angular/common";
import { AuthenticationComponent, OpenAppComponent, OtpComponent, VerifyPhoneComponent } from 'src/app/layout/share';
import { ShareService } from 'src/app/layout/share/services';

@Injectable()
export class CheckVerifyUser {
  /**
   * @author Zin
   * @param user Giá trị truyền vào để check object
   * @param user.type Truyền vào 'account_icheck' và 'info_sms'
   * @param user.type_token Truyền vào 'access_token' hoặc 'otpToken'
   */
  constructor(
    private modalController: ModalController,
    private location: Location,
    private shareService: ShareService) { }
  method: string = null;
  async verifyUser(user?: any) {
    if (!user) return false;
    switch (user.type) {
      case 'account_icheck': {
        if (user.type_token === 'access_token') {
          return true;
        }
        await this.openModalVerify();
        return false;
      }
      case 'info_sms': {
        if (user.type_token === 'access_token') {
          return true;
        }
        await this.openModalVerify();
        return false;
      }
      default: {
        return false;
      }
    }
  }
  async openModalVerify(campaign?: any, listGift?: any) {
    if (!campaign || !campaign?.type?.includes('qr_mar')) {
      let method_identificate = localStorage.getItem('method_identificate');
      switch (method_identificate) {
        case 'app_icheck': {
          const modal = await this.modalController.create({
            component: OpenAppComponent,
            cssClass: 'add-times-play-scan',
            componentProps: {
              'gifts': listGift,
              'campaign': campaign
            },
            backdropDismiss: false,
          });
          return await modal.present();
        }
        case 'info_sms': {
          const modal = await this.modalController.create({
            component: OtpComponent,
            cssClass: 'add-times-play-scan',
            componentProps: {
              'target': localStorage.getItem('targetCampaign'),
              'campaign_id': campaign ? campaign.id : null
            },
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(async (res: any) => {
            const data = res?.data;
            if (data) {
              const location = this.location.path();
              const url = `${location}${location.search('&') >= 0 ? '&' : '?'}phone=${data.phone}`;
              // this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
              this.location.replaceState(`${url}`);
              window.location.reload();
              // this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
            }
          });
          return await modal.present();
        }
        case 'account_icheck': {
          const modal = await this.modalController.create({
            component: AuthenticationComponent,
            cssClass: 'verify',
            componentProps: {
              'gifts': listGift,
              'campaign': campaign
            },
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(async (res: any) => {
            const data = res?.data;
            if (data) {
              const location = this.location.path();
              const url = `${location}${location.search('&') >= 0 ? '&' : '?'}phone=${data.phone}`;
              // this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
              this.location.replaceState(`${url}`);
              window.location.reload();
              // this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
            }
          });
          return await modal.present();
        }
        default: {
          const modal = await this.modalController.create({
            component: VerifyPhoneComponent,
            cssClass: 'popup-confirm-phone',
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(async (res: any) => {
            const data = res?.data;
            if (data) {
              const location = this.location.path();
              const url = `${location}${location.search('&') >= 0 ? '&' : '?'}phone=${data.phone}`;
              // this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
              this.location.replaceState(`${url}`);
              this.shareService.sendOTPDone(true);
              window.location.reload();
            }
          });
          return await modal.present();
        }
      }
    } else {
      switch (campaign.campaign_condition?.web_app_condition) {
        case 'info_otp_required': {
          const modal = await this.modalController.create({
            component: OtpComponent,
            cssClass: 'add-times-play-scan',
            componentProps: {
              'target': localStorage.getItem('targetCampaign'),
              'campaign_id': campaign ? campaign.id : null
            },
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(async (res: any) => {
            const data = res?.data;
            if (data) {
              this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
            }
          });
          return await modal.present();
        }
        default: {
          const modal = await this.modalController.create({
            component: VerifyPhoneComponent,
            cssClass: 'popup-confirm-phone',
            backdropDismiss: false,
          });
          modal.onDidDismiss().then(async (res: any) => {
            const data = res?.data;
            if (data) {
              this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${data.phone}`);
              this.shareService.sendOTPDone(true);
            }
          });
          return await modal.present();
        }
      }
    }

  }
}
