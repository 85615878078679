import { ConfirmExchangeVnPointComponent } from './../vnpoints/layouts/confirm-exchange-coin/confirm-exchange-coin.component';
import { LackOfVnpointsComponent } from './../vnpoints/layouts/lack-of-vnpoints/lack-of-vnpoints.component';
import { PopupOutOfGiftComponent } from './../gift-detail/components/popup-out-of-gift/popup-out-of-gift.component';
import { GiftVnPointService } from './../vnpoints/services/gift-vnpoints.service';
import { CommonService } from './../../public/service/common.service';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GiftService } from '../gift-detail/services/gift-detail.service';

@Component({
  selector: 'app-lottery-gifts',
  templateUrl: './lottery-gifts.component.html',
  styleUrls: ['./lottery-gifts.component.scss'],
})
export class LotteryGiftsComponent implements OnInit {
  offset = 0;
  campaign: any = [];
  remain: number;
  status;
  waiting_draw = false;
  drawn = false;
  invalid_Code = false;
  isLoading = false;
  params = {
    phone: null,
    device_id: null,
    winner_gift_id: null,
  };
  countTotal = 0;
  constructor(
    private router: Router,
    private giftService: GiftService,
    private route: ActivatedRoute,
    public modalController: ModalController,
    private commonService: CommonService,
    public platform: Platform,
    public toastCtrl: ToastController
  ) {
    this.route.queryParams.subscribe(qparams => {
      console.log('params', qparams);
      if (qparams.deviceId) {
        this.params.device_id = qparams.deviceId;
      }

      if (qparams.phone) {
        this.params.phone = qparams.phone;
      }
      this.params.winner_gift_id = this.route.snapshot.paramMap.get('id')
    });
  }

  ngOnInit(): void {
    this.loadGiftDetail();
  }

  async loadGiftDetail() {
    // const params: any = {
    //   offset: this.offset,
    //   limit: 10,
    // }
    // return this.giftVnPointService.getDetailVnPoint(this.winnerGiftId).toPromise().then((res: any) => {
    //   if (res?.statusCode === 200) {
    //     this.campaign = res?.data;
    //     this.remain = (this.campaign.quantity - this.campaign.quantity_exchanged)
    //     this.isLoading = false;
    //     console.log("this.campaign=====>", this.remain);

    //   }
    // });

    if (this.params.device_id && this.params.winner_gift_id) {
      const body: any = {
        winner_gift_id: this.params.winner_gift_id,
        device_id: this.params.device_id
      };
      this.giftService.getDetailWinnerGift(body).subscribe(res => {
        if (res.statusCode === 200) {
          this.campaign = res?.data;

          if (this.campaign?.voucher?.checked_condition?.status) {
            return this.waiting_draw = true;
          } else {
            // return 'Hết hạn sử dụng';
            if (this.campaign?.voucher?.checked_condition?.code === 'START_TIME_CAN_USE') {
              return this.waiting_draw = true;
            } else if (this.campaign?.voucher?.checked_condition?.code === 'MAX_NUM_OF_USED_VOUCHER' || this.campaign?.voucher?.checked_condition?.code === 'MAX_NUM_OF_USED_CUSTOMER') {
              return this.drawn = true;
            } else if (this.campaign?.voucher?.checked_condition?.code === 'BUSINESS_LOCKED_VOUCHER' || this.campaign?.voucher?.checked_condition?.code === 'ADMIN_LOCKED_VOUCHER') {
              return this.drawn = true;
            } else {
              return this.invalid_Code = true;
            }
          }

          // this.can_mark_use = res?.data?.voucher?.can_mark_use
          // this.can_use = res?.data?.voucher?.can_use
          // this.mark_use = res?.data?.voucher?.mark_use
          // console.log("campaign======>", this.campaign.winner.voucher);

        }
      });
    }
  }

  genStyle(i) {
    return {
      right: 18 * i + 30 + 'px'
    };
  }

}
