import { ShareService } from './../../share/services/share.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  campaignId: any;
  deviceId: any;
  offset = 0;
  countTotal: any;
  phone: any;
  business_id: any;
  source: any;
  isLoading = true;
  histories: any = [];
  constructor(private route: ActivatedRoute,
              private shareService: ShareService,
              private router: Router) {
    // get path

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.campaignId = this.route.snapshot.params.id;
    // this.business_id = this.route.snapshot.params.business_id;
    this.phone = urlParams.get('phone');
    this.deviceId = urlParams.get('device_id');
    this.business_id = urlParams.get('business_id');
    this.source = urlParams.get('source');
    //get query param
    // this.campaignId = this.route.snapshot.queryParams.tuan;
  }

  ngOnInit() {
    this.loadHistory();
  }

  async onRedirCampaignDetail() {
    await this.router.navigate([`/campaign/${this.campaignId}`], {queryParams: {phone: this.phone, business_id: this.business_id, source: this.source}});
  }

  async onLanding() {
    await this.router.navigate([`landing/${this.business_id}`], {queryParams: {phone: this.phone, source: this.source}});
  }

  loadHistory() {
    const params = {} as any;
    params.offset = this.offset;
    params.limit = 10;
    if(this.phone) {
      params.phone = this.phone;
    }
    params.deviceId = this.deviceId;
    return this.shareService.getHistory(this.campaignId, params).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.histories = this.histories.concat(res?.data?.rows);
        this.countTotal = res?.data?.count;
        this.isLoading = false;
      }
    });
  }

  async onScrollDown() {
    console.log("bottom");
    if (this.countTotal >= this.offset) {
        this.offset = this.offset + 10;
        await this.loadHistory();
    }
}

}
