import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-popup-receive-gift-success',
  templateUrl: './popup-receive-gift-success.component.html',
  styleUrls: ['./popup-receive-gift-success.component.scss'],
})
export class PopupReceiveGiftVnPointSuccessComponent implements OnInit {

  @Input() data;

  currentUrl: any;
  previousUrl: any;
  currentGift: any;
  phone: any;
  // parent_campaign_id: any;
  imageAsset = '../../../../../assets/images';
  objectPoint: any;

  hideContactContentText: boolean = false;
  constructor(
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {

  }
  dismiss() {
    this.modalController.dismiss();
  }

  goApp() {
    window.open(environment.openAppiCheckReward, '_blank');
  }

}
