import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ShareService} from '../../services';
import {IonInput} from "@ionic/angular";

@Component({
  selector: 'app-authentication-register',
  templateUrl: './authentication-register.component.html',
  styleUrls: ['./authentication-register.component.scss'],
})
export class AuthenticationRegisterComponent implements OnInit, AfterViewInit {
  @Output() eventEmmiter = new EventEmitter<string>();
  step = 1;
  formRegister = this.fb.group({
    // tslint:disable-next-line:max-line-length
    phone: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(15), Validators.minLength(10)]],
    first_name: ['', [Validators.required, Validators.maxLength(255)]],
    last_name: ['', [Validators.required, Validators.maxLength(255)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirm_password: ['', [Validators.required, Validators.minLength(6)]],
  });
  formOtp = this.fb.group({
    otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
  })
  typePasswordConfirm = 'password';
  typePassword = 'password';
  time_retry_otp = 60;
  interval_time;
  label_phone = ''
  submited = false;
  register_fail = false;
  valid_confirm_password = false;
  message: string;
  phone;
  token;

  otp1: number;
  otp2: number;
  otp3: number;
  otp4: number;
  otp5: number;
  otp6: number;
  type: number;
  invaliOtp = true;
  disableButotn = false;
  @ViewChild('input1', {  static: false }) input1Element: IonInput;
  constructor(
      private fb: FormBuilder,
      private shareService: ShareService,
      private eRef: ElementRef
  ) { }

  ngOnInit() {
    this.step = 1;
  }
  inputChange(event?: any, type?: string) {
    this.valid_confirm_password = false;
    this.register_fail = false;
    if (type === 'phone') {
      if (this.formRegister.controls[type].valid) {
        this.submited = false;
        return;
      }
    }
    if (type === 'first_name') {
      if (this.formRegister.controls[type].valid  && this.formRegister.controls.phone.valid) {
        this.submited = false;
        return;
      }
    }
    if (type === 'last_name') {
      if (this.formRegister.controls[type].valid  && this.formRegister.controls.first_name.valid) {
        this.submited = false;
        return;
      }
    }
    if (type === 'password') {
      if (this.formRegister.controls[type].valid && this.formRegister.controls.last_name.valid) {
        this.submited = false;
        return;
      }
    }
    if (type === 'confirm_password') {
      if (this.formRegister.valid) {
        this.submited = false;
        return;
      }
    }
  }
  handleRegister() {
    this.submited = true;
    for (const i in this.formRegister.controls) {
      this.formRegister.controls[i].markAsDirty();
      this.formRegister.controls[i].updateValueAndValidity();
    }
    if (this.formRegister.invalid) {
      return;
    }
    if (this.formRegister.controls.password.value !== this.formRegister.controls.confirm_password.value) {
      this.valid_confirm_password = true;
      return;
    }
    const body = {
      first_name: this.formRegister.controls.first_name.value,
      last_name: this.formRegister.controls.last_name.value,
      phone: this.formRegister.controls.phone.value,
      password: this.formRegister.controls.password.value.trim()
    };
    this.shareService.registerCore(body).subscribe(
        res => {
          this.shareService.loginCore({
            username: res.phone,
            password: body.password
          }).subscribe(
              response => {
                const bodyOtp = {
                  phone: res.phone
                };
                this.phone = res.phone;
                this.token = response.access_token;
                this.shareService.sendOtpRegister(bodyOtp, this.token).subscribe(
                    res$ => {
                      const phone = res.phone;
                      const length = phone.length;
                      const c = length % 3;
                      if (c) {
                        const d = phone.slice(0, c);
                        const d2 = phone.slice(c, length);
                        let d3 = '';
                        for (let i = 0; i < d2.length / 3; i++) {
                          const p = d2.slice(3 * i, 3 * i + 3);
                          d3 = d3 + ' ' + p;
                        }
                        this.label_phone = d + d3.slice(1, d3.length);
                      } else {
                        let d = '';
                        for (let i = 0; i < length / 3; i++) {
                          const p = phone.slice(3 * i, 3 * i + 3);
                          d = d + ' ' + p;
                        }
                        this.label_phone = d.slice(1, d.length);
                      }
                      this.label_phone = '+84 ' + this.label_phone.substring(1, this.label_phone.length);
                      this.step = 2;
                      this.time_retry_otp = 60;
                      this.interval_time = setInterval(() => {
                        this.time_retry_otp = this.time_retry_otp - 1;
                        if (this.time_retry_otp === 0) {
                          clearInterval(this.interval_time);
                        }
                      }, 1000);
                    },
                    err$ => {
                      this.register_fail = true;
                      this.message = err$;
                    }
                );
              }
          );
        },
        err => {
          this.register_fail = true;
          this.message = err;
          if (err === 'Phone or Email already exist') {
            this.message = 'Số điện thoại đã tồn tại';
          }
        }
    );
  }
  submitOtp() {
    const otp$ = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
    if (otp$.length === 54) {
      return;
    }
    if (otp$.length !==6 && otp$.length !== 54) {
      console.log('valid otp');
      alert('Mã xác nhận không hợp lệ!');
      return;
    }
    // const otp = this.formOtp.controls.otp.value;
    const body = {
      phone: this.phone,
      otp: otp$
    };
    this.disableButotn = true;
    this.shareService.confirmOtpRegister(body, this.token).subscribe(
        res => {
          this.disableButotn = false;
          localStorage.setItem('phoneLogin', this.phone);
          this.eventEmmiter.emit('close');
        },
        err => {
          this.disableButotn = false;
          alert('Mã xác thực không hợp lệ!');
        }
    );
    return;
  }
  retrySendOtp() {
    if (this.time_retry_otp === 0) {
      this.shareService.retryOtp({
        phone: this.phone
      }).subscribe(
          res => {
            if (res.status === true) {
              this.time_retry_otp = 60;
              this.interval_time = setInterval(() => {
                this.time_retry_otp = this.time_retry_otp - 1;
                if (this.time_retry_otp === 0) {
                  clearInterval(this.interval_time);
                }
              }, 1000);
              return;
            }
            alert(res);
          },
          err => {
            alert(err);
          }
      );
    }
  }
  changeTypePasswordConfirm(type: string, data: number) {
    if (data === 1) {
      if (type === 'password') {
        this.typePassword = 'non_password';
        return;
      }
      this.typePassword = 'password';
      return;
    }
    if (data === 2) {
      if (type === 'password') {
        this.typePasswordConfirm = 'non_password';
        return;
      }
      this.typePasswordConfirm = 'password';
    }

  }

  redirectLogin() {
    this.eventEmmiter.emit('login');
  }
  closeModal() {
    this.eventEmmiter.emit('close');
  }
  setInterval() {
    this.time_retry_otp = 60;
    this.interval_time = setInterval(() => {
      this.time_retry_otp = this.time_retry_otp - 1;
      if (this.time_retry_otp === 0) {
        clearInterval(this.interval_time);
      }
    }, 1000);
  }
  clickIonItem(type) {
    this.type = type;
  }
  onInputEntry(event, nextInput, lastInput?, type?: string, inputNextFocus?: number) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    const value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (!value.includes(input.value)) {
      event.target.value = event.target.value.slice(0, maxLength);
      this[type] = event.target.value;
      console.log(this.otp1, this.otp2, this.otp3, this.otp4, this.otp5, this.otp6)
    }
    if (length >= maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
      this.type = inputNextFocus;
      console.log(this.type);
      nextInput.setFocus();
    }
    const arr = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
    console.log('input change');
    this.invaliOtp = true;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arr.length; i++) {
      if (this[arr[i]] !== null && this[arr[i]] !== undefined) {
        this.invaliOtp = false;
        break;
      }
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.type = undefined
    }
  }
  onKeydown($event, value, lastInput, t, nextInput) {
    if ($event.key === 'Backspace') {
      this[value] = undefined;
      $event.target.value = null;
      setTimeout(() => {
        lastInput.setFocus();
        this.type = t;
        const arr = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
        this.invaliOtp = false;
        if (arr.every((value) => this[value] === undefined)) {
          console.log('remove item');
          this.invaliOtp = true;
        }
      }, 10);
    } else {
      if ($event.target.value) {
        nextInput.setFocus();
      }
    }
  }
  ngAfterViewInit() {
    if (this.type === 2) {
      console.log(1);
      setTimeout(() => {
        console.log(2);
        this.input1Element.setFocus();
      }, 1000);
    }
  }
}
