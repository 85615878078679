import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-lack-of-vnpoints',
  templateUrl: './lack-of-vnpoints.component.html',
  styleUrls: ['./lack-of-vnpoints.component.scss'],
})
export class LackOfVnpointsComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
  ) { }

  @Input() type;

  selectStamp: any = {
    title: ''
  };

  ngOnInit() {
    console.log("type=====>", this.type);
    
  }

  enterCode() {
    this.modalCtrl.dismiss({
      enterCode: true
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
