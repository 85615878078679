import { GiftVnPointService } from './../../services/gift-vnpoints.service';
import { PopupOutOfGiftComponent } from './../../../gift-detail/components/popup-out-of-gift/popup-out-of-gift.component';

import { ReceivedPointComponent } from './../../../accumulate-point/components/received-point/received-point.component';
import { EnterCodePointComponent } from './../../../accumulate-point/components/enter-code-point/enter-code-point.component';
import { LackOfPointComponent } from './../../../accumulate-point/components/lack-of-point/lack-of-point.component';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import {
  BuyCardComponent
} from '../../../share';
import { track } from "../../../../helpers/tracking-teko";
import { GiftService } from '../../../gift-detail/services/gift-detail.service';
import { PopupReceiveGiftVnPointSuccessComponent } from '../popup-receive-gift-success/popup-receive-gift-success.component';
import { ShareService } from 'src/app/layout/share/services';
@Component({
  selector: 'app-confirm-exchange-coin',
  templateUrl: './confirm-exchange-coin.component.html',
  styleUrls: ['./confirm-exchange-coin.component.scss'],
})
export class ConfirmExchangeVnPointComponent implements OnInit {
  @Input() campaign;

  hasErr;
  imageAsset = '../../../../../assets/images';
  warningMsg = '';
  currentUrl: any;
  userInfo: any;
  currentCampaign: any;
  isLoading: boolean = false;
  isData: any;


  constructor(
    private modalController: ModalController,
    private router: Router,
    private giftService: GiftService,
    private giftVnPointService: GiftVnPointService,
    private route: ActivatedRoute
  ) {
    this.currentUrl = this.router.url;
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  }


  ngOnInit() {
    console.log("campaign ===>", this.campaign);

    // tracking
    track('manualTrackVisibleContentImpressions', {
      regionName: 'popup',
      contentName: 'Xac nhan doi diem',
      index: 0,
    });

    // end
    console.log('campaign', this.campaign);
    const param = {} as any;
    this.giftService.getCampaign(param, { form: true }).subscribe(res => {
      this.currentCampaign = res.data;
    })

    const element = <HTMLElement>document.getElementsByClassName('modal-shadow')[0];
    if (element) {
      element.style.top = '100%';
    }
  }


  dismiss() {
    this.modalController.dismiss();
  }

  isSubmiting = false;

  async confirmInfoUser() {
    this.isLoading = true;
    const body = {
      vnpoint_gift_id: this.campaign.id
    }
    this.giftVnPointService.postExchange(body).subscribe(async res => {
      this.isData = res;
      
      // this.modalController.dismiss(res => {
      //   console.log(res, 'close modal');

      // });
      if (this.isData.statusCode == 200) {
        this.modalController.dismiss({
          type: 'confirm'
        });
        // const modal = await this.modalController.create({
        //   component: PopupReceiveGiftVnPointSuccessComponent,
        //   cssClass: 'popup-receive-exchange-gift',
        //   backdropDismiss: false,
        // });
        // return await modal.present();
      } else {
        alert(this.isData.data.message)
      }
    }, err => {
      alert(err)
    })
  }


}
