import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { IonInput, ModalController } from "@ionic/angular";
import { FormBuilder, Validators } from "@angular/forms";
import { ShareService } from "../services";
import { $e } from "codelyzer/angular/styles/chars";
import { Location } from '@angular/common';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit, AfterViewInit {
  @Input() target;
  @Input() campaign_id;
  @Input() user_id;
  @Input() phoneDefault;
  @Input() isVeryUser;
  step = 1;
  // tslint:disable-next-line:variable-name
  time_retry_otp = 60;
  // tslint:disable-next-line:variable-name
  interval_time;
  form = this.fb.group({
    // phone: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(14), Validators.minLength(9)]],
    phone: ['', [Validators.required, Validators.pattern(new RegExp('(84|0[3|5|7|8|9])+([0-9]{8})\\b'))]],
  });
  formOtp = this.fb.group({
    otp: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(6), Validators.minLength(6)]],
  });
  // tslint:disable-next-line:variable-name
  phone_label;
  phone;
  otp1: number;
  otp2: number;
  otp3: number;
  otp4: number;
  otp5: number;
  otp6: number;
  type: number;
  invaliOtp = true;
  disableButotn = false;
  isSubmit = false;
  @ViewChild('input1', { static: false }) input1Element: IonInput;
  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private location: Location,
    private shareService: ShareService,
    private eRef: ElementRef
  ) { }
  ngOnInit() {
    this.type = undefined;
    this.step = 1;
    // this.step = 2;
    if (this.phoneDefault) {
      this.form.get('phone').setValue(this.phoneDefault);
      this.phone = this.phoneDefault;
    }
  }
  confirmPhone() {
    this.isSubmit = true;
    if (this.form.invalid) {
      // alert('Số điện thoại không hợp lệ!');
      return;
    }

    const userVery = JSON.parse(localStorage.getItem('userVery'));

    if (userVery?.filter(item => item === this.form.get('phone').value)?.length && this.isVeryUser) {
      this.modalController.dismiss({ isSamePhone: true });
      return;
    }
    // const phone  = this.form.controls.phone.value.trim();
    let phone = this.form.controls.phone.value.trim();
    if (phone.split('')[0] !== 0 && phone.length === 9) {
      phone = `0${phone}`;
    }
    this.phone = phone;
    if (!this.phoneDefault) {
      this.location.replaceState(`${this.location.path().split('?')[0]}?phone=${this.phone}`);
    }
    this.shareService.getOtp({
      phone,
      target: this.target,
      user_id: this.user_id
    }).subscribe(
      res => {
        if (res.statusCode === 200) {
          console.log(this.phone);
          const phone = this.form.controls.phone.value.trim();
          const length = phone.length;
          const c = length % 3;
          if (c) {
            const d = phone.slice(0, c);
            const d2 = phone.slice(c, length);
            let d3 = '';
            for (let i = 0; i < d2.length / 3; i++) {
              const p = d2.slice(3 * i, 3 * i + 3);
              d3 = d3 + ' ' + p;
            }
            this.phone_label = d + d3.slice(1, d3.length);
          } else {
            let d = '';
            for (let i = 0; i < length / 3; i++) {
              const p = phone.slice(3 * i, 3 * i + 3);
              d = d + ' ' + p;
            }
            this.phone_label = d.slice(1, d.length);
          }
          if (this.phone.split('')[0] === 0) {
            this.phone_label = '+84 ' + this.phone_label.substring(1, this.phone_label.length);
          } else {
            this.phone_label = '+84 ' + this.phone_label;
          }
          this.step = 2;
          setTimeout(() => {
            this.input1Element.setFocus();
            // new this.input1Element.ionFocus(true)
          }, 1000);
          this.setInterval();
          return;
        }
        alert(res.data.message);
      },
      err => {
        alert(err);
      }
    );
  }
  confirmOtp() {
    const otp$ = `${this.otp1}${this.otp2}${this.otp3}${this.otp4}${this.otp5}${this.otp6}`;
    if (otp$.length === 54) {
      return;
    }
    if (otp$.length !== 6 && otp$.length !== 54) {
      return;
    }
    const body = {
      phone: this.phone,
      otp: otp$
    };
    this.disableButotn = true;
    this.shareService.sendOtp(body).subscribe(
      res => {
        console.log(res);
        this.disableButotn = false;
        if (res.statusCode === 200) {

          if (this.isVeryUser) {
            localStorage.setItem('userVery', JSON.stringify([this.form.get('phone').value]));
          }

          localStorage.setItem('otpToken', res.data.token);
          this.modalController.dismiss({ phone: this.phone });
          setTimeout(() => {
            this.shareService.sendOTPDone(true);
          }, 1000);
          return;
        }
        alert(res.data.message);
      },
      err => {
        this.disableButotn = false;
        alert(err);
      }
    );
  }
  closeModal() {
    this.modalController.dismiss();
  }
  retrySendOtp() {
    if (this.time_retry_otp === 0) {
      this.shareService.getOtp({
        phone: this.phone,
        target: this.target,
        user_id: this.user_id
      }).subscribe(
        res => {
          if (res.statusCode === 200) {
            this.time_retry_otp = 60;
            this.interval_time = setInterval(() => {
              this.time_retry_otp = this.time_retry_otp - 1;
              if (this.time_retry_otp === 0) {
                clearInterval(this.interval_time);
              }
            }, 1000);
            return;
          }
          alert(res);
        },
        err => {
          alert(err);
        }
      );
    }
  }
  setInterval() {
    this.time_retry_otp = 60;
    this.interval_time = setInterval(() => {
      this.time_retry_otp = this.time_retry_otp - 1;
      if (this.time_retry_otp === 0) {
        clearInterval(this.interval_time);
      }
    }, 1000);
  }
  clickIonItem(type) {
    // this.input1Element.setFocus();
    this.type = type;
  }
  onInputEntry(event, nextInput, lastInput?, type?: string) {
    const input = event.target;
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;

    const value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (!value.includes(input.value)) {
      event.target.value = event.target.value.slice(0, maxLength);
      this[type] = event.target.value;
    }
    if (length >= maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
      nextInput.setFocus();
    }
    const arr = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
    this.invaliOtp = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arr.length; i++) {
      if (this[arr[i]] === null || this[arr[i]] === undefined) {
        this.invaliOtp = true;
      }
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.type = undefined;
    }
  }
  onKeydown($event, value, lastInput, t, nextInput) {
    if ($event.key === 'Backspace') {
      this[value] = undefined;
      $event.target.value = null;
      setTimeout(() => {
        lastInput.setFocus();
        this.type = t;
      }, 10);
    } else {
      if ($event.target.value) {
        nextInput.setFocus();
      }
    }
  }
  ngAfterViewInit() {
    if (this.step === 2) {
      console.log(1);
      // setTimeout(() => {
      //   console.log(2);
      //   this.input1Element.setFocus();
      //   // new this.input1Element.ionFocus(true)
      // }, 1000);
    }
  }
}
