import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
@Injectable({
    providedIn: 'root'
})
export class GiftVnPointService {
    private deviceSubject = new BehaviorSubject<any>({});
    deviceId = this.deviceSubject.asObservable();

    constructor(private httpClient: HttpClient) {
        this.deviceSubject = new BehaviorSubject<string>(null);
        this.deviceId = this.deviceSubject.asObservable();
        this.getDeviceId();
        this.getToken();
    }

    headerLocation: any = {};
    header;
    setHeader(location: any) {
        this.headerLocation = Object.assign({}, location);
        this.getDeviceId();
    }
    getDeviceId() {
        // nhận deviceId từ url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let deviceId = urlParams.get('deviceId');

        if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
            deviceId = localStorage.getItem('device_id');
        }
        if (!deviceId || deviceId === 'null' || deviceId === 'undefined') {
            FingerprintJS.load().then(fp => {
                fp.get().then(async result => {
                    deviceId = await result.visitorId;
                    localStorage.setItem('device_id', deviceId);
                    this.deviceSubject.next(deviceId);
                    // tạm thời cho reload lại hj
                    window.location.reload();
                });
            });
        } else {
            localStorage.setItem('device_id', deviceId);
            this.deviceSubject.next(deviceId);
        }
    }
    getToken() {
        // nhận deviceId từ url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let token_vnpoint = urlParams.get('token');

        if (!token_vnpoint || token_vnpoint === 'null' || token_vnpoint === 'undefined') {
            token_vnpoint = localStorage.getItem('device_id');
        }
        if (!token_vnpoint || token_vnpoint === 'null' || token_vnpoint === 'undefined') {
            FingerprintJS.load().then(fp => {
                fp.get().then(async result => {
                    token_vnpoint = await result.visitorId;
                    localStorage.setItem('token_vnpoint', token_vnpoint);
                    this.deviceSubject.next(token_vnpoint);
                    // tạm thời cho reload lại hj
                    window.location.reload();
                });
            });
        } else {
            localStorage.setItem('token_vnpoint', token_vnpoint);
            this.deviceSubject.next(token_vnpoint);
        }
    }
    getVNPoint(params) {
        // this.checkToken();
        const token = localStorage.getItem('token_vnpoint');
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...this.headerLocation
        });
        return this.httpClient.get(`${environment.urlDev}${environment.auth}customer/vnpoints/gifts`, { params, headers: header });
    }

    getDetailVnPoint(id: any) {
        // this.checkToken();
        const token = localStorage.getItem('token_vnpoint');
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...this.headerLocation
        });
        return this.httpClient.get(`${environment.urlDev}${environment.auth}customer/vnpoints/gifts/detail/${id}`,{headers: header});
    }

    postExchange(body: any) {
        // this.checkToken();
        const token = localStorage.getItem('token_vnpoint');
        const header = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...this.headerLocation
        });
        return this.httpClient.post(`${environment.urlDev}${environment.auth}customer/vnpoints/gifts/exchange`, body, {headers: header});
    }

    // checkToken() {
    //     const header = localStorage.getItem('token_vnpoint');
    //     this.header = new HttpHeaders({
    //         Authorization: `Bearer ${header}`,
    //         'Content-Type': 'application/json',
    //         ...this.headerLocation
    //     });
    // }
}