import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-open-app',
  templateUrl: './open-app.component.html',
  styleUrls: ['./open-app.component.scss'],
})
export class OpenAppComponent implements OnInit {
  @Input() gifts;
  @Input() campaign;
  constructor(
      private modalController: ModalController,
  ) { }

  ngOnInit() {
    console.log(this.gifts[0]?.name.length, this.gifts)
  }
  openApp() {
    window.open(environment.openAppiCheck);
  }
  closeModal() {
    this.modalController.dismiss();
  }
}
