import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../share/services/share.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GiftVnPointService } from '../services/gift-vnpoints.service';
@Component({
  selector: 'app-app-gift-exchanges',
  templateUrl: './app-gift-exchanges.component.html',
  styleUrls: ['./app-gift-exchanges.component.scss'],
})
export class AppGiftExchangesComponent implements OnInit {
  parent_campaign_id: any;
  unsubscribe$ = new Subject<void>();
  name: any;
  countTotal: any;
  keys: any;
  params = {
    token: null,
    deviceId: null,
    source: null
  }
  isLoading = true;
  delayTimer: any;
  offset = 0;
  campaign: any = {}; // Đây là chương trình sau khi loadData
  giftSelected: any = null;
  gifts: any = []

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private giftVnPointService: GiftVnPointService,
  ) {
    this.route.queryParams.subscribe(qparams => {
      console.log('params', qparams);
      if (qparams.source) {
        this.params.source = qparams.source;
      }
      if (qparams.token) {
        this.params.token = qparams.token;
      }
      if (qparams.deviceId) {
        this.params.deviceId = qparams.deviceId;
      }
    });
   }



  async onGiftDetail(item: any) {
    console.log('gift_detail', item.id);

    this.giftSelected = item;
    const url = `/landing-app/vnpoint-market/detail/${item.id}`; // Đã sửa
    this.routerNavigate(url);
  }

  routerNavigate(url: string) {
    // , { queryParams: {campaign_id: this.giftSelected.id  } }
      this.router.navigate([url],{queryParams: {source: this.params.source , token: this.params.token, deviceId: this.params.deviceId}} );
  }

  async ngOnInit() {
    this.loadData()
  }

  loadData() {
    const query: any = {
      offset: this.offset,
      limit: 100,
      name: this.name ? this.name.trim() : null,
    }
    this.removeEmptyQuery(query);
    return this.giftVnPointService.getVNPoint(query).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.gifts = res?.data?.rows;
        this.countTotal = res?.data?.count;
        this.isLoading = false;
      }
    });

  }

  async onScrollDown() {
    console.log("bottom");
    if (this.countTotal >= this.offset) {
      this.offset = this.offset + 10;
      await this.loadData();
    }
  }
  
  async removeEmptyQuery(query: any) {
    Object.keys(query).forEach(key => {
      
      if (query[key] === undefined || query[key] === null || query[key] === '') {
        delete query[key];
      }
    });
    console.log("query==>", query);
    
    return query;
  }
  search(item) {
    this.keys = item.key;
    console.log("keys===>",this.keys);
    
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.loadData();
    }, 300);
  }

  clear(){
    this.name = null;
    this.keys = null;
    this.loadData();
  }

}
