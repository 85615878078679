import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  @Input() gifts;
  @Input() campaign;
  id;
  target;
  code;
  type= 'authen';
  constructor(
      private router: Router,
      private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.type = 'authen';
  }
  closeModal() {
    this.modalController.dismiss();
  }
  checkEventEmmiter(event) {
    console.log(event);
    this.type = event;
    if (event.phone) {
      this.modalController.dismiss({phone: event.phone});
      return;
    }
    if (event === 'close') {
      this.closeModal();
      return;
    }
  }
  redirectRouter(event) {
   this.type = event;
  }
}
