import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ShareService} from "../../services";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  step = 1;
  interval_time;
  time_retry_otp = 60;
  formForgot =  this.fb.group({
    phone: ['', [Validators.required, Validators.pattern('[0-9]*')]]
  });
  formPassword =  this.fb.group({
    otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
    password: ['', [Validators.required]],
    // tslint:disable-next-line:max-line-length
    confirm_password: ['', [Validators.required]],
  });
  typePasswordConfirm = 'password';
  typePassword = 'password';
  phone;
  constructor(
      private fb: FormBuilder,
      private shareService: ShareService

  ) { }
  @Output() eventEmmiter = new EventEmitter<string>();
  ngOnInit() {
    this.step = 1;
  }
  confirmPhone() {
    const phone = this.formForgot.controls.phone.value;
    if (this.formForgot.invalid) {
      alert('Số điện thoại không hợp lệ');
      return;
    }
    this.shareService.retryOtp({
      phone: `0${phone.trim()}`
    }).subscribe(
        res => {
          this.phone = `0${phone.trim()}`;
          if (res.status === true) {
            this.step = 2;
            this.interval_time = setInterval(() => {
              this.time_retry_otp = this.time_retry_otp - 1;
              if (this.time_retry_otp === 0) {
                clearInterval(this.interval_time);
              }
            }, 1000);
            return;
          }
          alert(res);
        },
        err => {
          alert(err);
        }
    )
    return;
  }
  handleForgotPass() {
    const otp = this.formPassword.controls.otp.value;
    const password = this.formPassword.controls.password.value;
    const confirm_password = this.formPassword.controls.confirm_password.value;
    if (this.formPassword.invalid) {
      if (!otp.trim() || !password || !confirm_password) {
        alert('Chưa nhập đầy đủ thông tin!');
        return;
      }
      alert('Thông tin không hợp lệ!')
      return;
    }
    if (otp.trim().length !== 6) {
      alert('Mã xác thực không hợp lệ!');
      return;
    }
    const body = {
      phone: this.phone,
      otp: this.formPassword.controls.otp.value,
      // old_password: '',
      password: this.formPassword.controls.password.value,
      confirmed_password: this.formPassword.controls.confirm_password.value
    };
    this.shareService.resetPassowrd(body).subscribe(
        res => {
          this.redirectLogin();
          localStorage.setItem('phoneLogin', this.phone.trim());
        },
        err => {
          alert(err);
        }
    )
  }
  closeModal() {
    this.eventEmmiter.emit('close');
  }
  retrySendOtp() {
    if (this.time_retry_otp === 0) {
      this.shareService.retryOtp({
        phone: this.phone
      }).subscribe(
          res => {
            if (res.status === true) {
              this.time_retry_otp = 60;
              this.interval_time = setInterval(() => {
                this.time_retry_otp = this.time_retry_otp - 1;
                if (this.time_retry_otp === 0) {
                  clearInterval(this.interval_time);
                }
              }, 1000);
              return;
            }
            alert(res);
          },
          err => {
            alert(err);
          }
      );
    }
  }
  setInterval() {
    this.interval_time = setInterval(() => {
      this.time_retry_otp = this.time_retry_otp - 1;
      if (this.time_retry_otp === 0) {
        clearInterval(this.interval_time);
      }
    }, 1000);
  }
  changeTypePasswordConfirm(type: string, data: number) {
    if (data === 1) {
      if (type === 'password') {
        this.typePassword = 'non_password';
        return;
      }
      this.typePassword = 'password';
      return;
    }
    if (data === 2) {
      if (type === 'password') {
        this.typePasswordConfirm = 'non_password';
        return;
      }
      this.typePasswordConfirm = 'password';
    }

  }
  redirectLogin() {
    this.eventEmmiter.emit('login');
  }
}
