import { LackOfVnpointsComponent } from './../layouts/lack-of-vnpoints/lack-of-vnpoints.component';
import { ConfirmExchangeVnPointComponent } from './../layouts/confirm-exchange-coin/confirm-exchange-coin.component';
import { PopupReceiveGiftVnPointSuccessComponent } from './../layouts/popup-receive-gift-success/popup-receive-gift-success.component';
import { ConfirmExchangeCoinComponent } from './../../gift-detail/components/confirm-exchange-coin/confirm-exchange-coin.component';
import { PopupOutOfGiftComponent } from './../../gift-detail/components/popup-out-of-gift/popup-out-of-gift.component';
import { ReceivedPointComponent } from './../../accumulate-point/components/received-point/received-point.component';
import { EnterCodePointComponent } from './../../accumulate-point/components/enter-code-point/enter-code-point.component';
import { PopupReceiveGiftSuccessComponent } from './../../gift-detail/components/popup-receive-gift-success/popup-receive-gift-success.component';
import { PopupExchangeGiftFromStoreComponent } from './../../gift-detail/components/popup-exchange-gift-from-store/popup-exchange-gift-from-store.component';
import { PopupDeniedReceiveGiftComponent } from './../../gift-detail/components/popup-denied-receive-gift/popup-denied-receive-gift.component';
import { BuyCardComponent } from './../../share/buy-card/buy-card.component';
import { LackOfPointComponent } from './../../accumulate-point/components/lack-of-point/lack-of-point.component';
import { Subscription } from 'rxjs';
import { CheckVerifyUser } from './../../../public/lib/verify-user/verify-user';
import { CommonService } from './../../../public/service/common.service';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService } from '../../share/services/share.service';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { typeGift } from 'src/app/public/constant/constant';
import { GiftVnPointService } from '../services/gift-vnpoints.service';

@Component({
  selector: 'app-gift-detail-vnpoints',
  templateUrl: './gift-detail-vnpoints.component.html',
  styleUrls: ['./gift-detail-vnpoints.component.scss'],
})
export class GiftDetailVnpointsComponent implements OnInit {
  gift_id: any;
  offset = 0;
  campaign: any = [];
  remain: number;
  isLoading = false;
  params = {
    token: null,
    deviceId: null,
    source: null
  }
  countTotal = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public modalController: ModalController,
    private commonService: CommonService,
    private giftVnPointService: GiftVnPointService,
    public platform: Platform,
    public toastCtrl: ToastController
  ) {
    this.route.queryParams.subscribe(qparams => {
      console.log('params', qparams);
      if (qparams.source) {
        this.params.source = qparams.source;
      }
      if (qparams.token) {
        this.params.token = qparams.token;
      }
      if (qparams.deviceId) {
        this.params.deviceId = qparams.deviceId;
      }
      this.gift_id = this.route.snapshot.paramMap.get('id')
    });
  }

  TYPE_GIFT = typeGift;

  ngOnInit(): void {
    this.loadGiftDetail();
  }

  async loadGiftDetail() {
    // const params: any = {
    //   offset: this.offset,
    //   limit: 10,
    // }
    return this.giftVnPointService.getDetailVnPoint(this.gift_id).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.campaign = res?.data;
        this.remain = (this.campaign.quantity - this.campaign.quantity_exchanged)
        this.isLoading = false;
        console.log("this.campaign=====>", this.remain);

      }
    });
  }

  getStatusVoucher(voucher) {
    return this.commonService.getStatusVoucher(voucher);
  }

  genStyle(i) {
    return {
      right: 18 * i + 30 + 'px'
    };
  }

  backHome() {
    this.router.navigate(['landing-app/vnpoint-market'], { queryParams: { source: this.params.source, token: this.params.token, deviceId: this.params.deviceId } });
  }

  async exChangeGift() {
    if ((this.remain) <= 0) {
      console.log(1);
      const modal = await this.modalController.create({
        component: PopupOutOfGiftComponent,
        cssClass: 'popup-out-of-gift',
        backdropDismiss: false,
      });
      return await modal.present();
    } else if (+this.campaign?.customer?.points < +this.campaign?.point_exchange) {

      console.log(2);
      const modal = await this.modalController.create({
        component: LackOfVnpointsComponent,
        cssClass: 'add-times-play-scan',
        backdropDismiss: false,
      });
      return await modal.present();
    }
    else if (moment().isAfter(moment(this.campaign?.redemption_period_at))) {
      const modal = await this.modalController.create({
        component: LackOfVnpointsComponent,
        cssClass: 'add-times-play-scan',
        backdropDismiss: false,
        componentProps: {
          type: 'expired'
        }
      });
      return await modal.present();
    } else {
      console.log(3);

      const modal = await this.modalController.create({
        component: ConfirmExchangeVnPointComponent,
        cssClass: 'popup-confirm-exchange-gift',
        backdropDismiss: false,
        componentProps: {
          campaign: this.campaign
        }
      });
      this.loadGiftDetail();
      modal.onDidDismiss().then(async result => {
        console.log("result======>", result);
        
        if( result.data.type === 'confirm'){
        const modal = await this.modalController.create({
          component: PopupReceiveGiftVnPointSuccessComponent,
          cssClass: 'popup-receive-exchange-gift',
          backdropDismiss: false,
        });
        return await modal.present();
        }

      });
    
      return await modal.present();
    }
  }

}
