import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocationInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let location: any = JSON.parse(localStorage.getItem('location'));
    // if (location && location.lon) {
      const req = request.clone({
        // headers: request.headers.append('lon', 'Bearer 123')
        // setHeaders: {
        //   'lon': `123`,
        //   'lat': `456`
        // }
        // headers: request.headers
        // .set('lon', `${location.lon}`)
        // .set('lat', location.lat)
      });
      return <any>next.handle(req)
    // } else {
    //   return next.handle(request)
    // }
  }
}
