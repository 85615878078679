import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GiftService } from '../../services/gift-detail.service';
import { PopupGiftCoinCardComponent } from '../popup-gift-coin-card/popup-gift-coin-card.component';

@Component({
  selector: 'app-popup-out-of-gift',
  templateUrl: './popup-out-of-gift.component.html',
  styleUrls: ['./popup-out-of-gift.component.scss'],
})
export class PopupOutOfGiftComponent implements OnInit {
  @Input() campaignId;
  @Input() giftId;
  @Input() gift;
  @Input() phone;
  @Input() target;

  imageAsset ='../../../../../assets/images';

  constructor(
    private modalController: ModalController,
    private router: Router,
    private giftService: GiftService,
    private route: ActivatedRoute
  ) { 
  
  }


  ngOnInit() {
    
  }


  dismiss() {
    this.modalController.dismiss();
  }
}
