import { Component, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter, Input } from '@angular/core';
import { UploadXHRArgs, UploadChangeParam, UploadFile, InputBoolean, InputNumber, NzMessageService } from 'ng-zorro-antd';
import { of, timer, Observable, Observer, from } from 'rxjs';
import { map, take, tap, switchMap } from 'rxjs/operators';
import { UploadService } from '../services';

@Component({
  selector: 'app-i-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {

  @Input() @InputNumber() maxSize = 0;
  @Input() fileType: string;
  @Input() fileExt: string;
  @Input() @InputBoolean() multiple: boolean = false;
  @Output() change = new EventEmitter<UploadChangeParam>();
  @Output() done = new EventEmitter<void>();

  @Input() sizes: any[] = [];
  @Output() returnSize = new EventEmitter<any>();
  @Input() variant: any;
  @Input() index_variant: any;
  @Input() code: any;
  @Input() maxImage: number;

  size: string;

  showUploadButton = true;
  uploadError: string;
  public showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  }
  @Input() fileList: any = [];

  constructor(private upload: UploadService,

    private message: NzMessageService,
  ) { }

  ngOnInit() {
    if (this.maxImage === undefined) {
      this.maxImage = 20;
    }

    if (!this.fileList) {
      this.fileList = [];
    }
  }


  beforeUpload = (file: UploadFile) => {
    const promise = new Promise<UploadFile>((resolve, reject) => {
      if (!this.isImage(file.type)) {
        resolve(file);
      }

      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        file.dimensions = {
          width,
          height
        };
        resolve(file);
      };
    });

    return from(promise).pipe(map(file => {
      if (this.fileType) {
        const type = file.type;
        if (!type || this.fileType.indexOf(type) < 0) {
          this.message.create('error', 'Định dạng file không được hỗ trợ');
          return false;
        }
      }

      if (this.fileExt) {
        const ext = file.name.split('.').pop().toLowerCase();

        if (!ext || this.fileExt.toLowerCase().indexOf(ext) < 0) {
          this.message.create('error', 'Định dạng file không được hỗ trợ');
          return false;
        }
      }

      // if (file.size > 5000000) {
      //   this.message.create('error', 'Dung lượng file vượt quá 5MB');
      //   return false;
      // }

      if (this.sizes.length) {
        if (!(file.dimensions.width == this.sizes[0].width && file.dimensions.height == this.sizes[0].height)) {
          this.message.create("error", `Kích cỡ hình ảnh không phù hợp ( ${this.sizes[0].width} x ${this.sizes[0].height} )`);
          return false;
        }
      }
      return true;

    }));
  }

  tusUpload = (item: UploadXHRArgs) => {
    if (item.file) {return this.upload.postImg(item.file).subscribe(
      (res) => item.onSuccess!({
        id: res.data.url,
        url: res.data.url,
      }, item.file!, null),
      (err) => {
        this.message.create("error", `Ảnh quá nặng vui lòng upload ảnh nhẹ hơn.`);
      }
    );
    }
  }

  onChange($event: UploadChangeParam) {
    if ($event.fileList.filter(file => file.status !== 'done').length > 0) {
      this.showUploadButton = false;
    } else {
      this.showUploadButton = true;
      this.done.emit();
    }

    if (this.variant) {
      $event['obj_variant'] = this.variant;
    }

    if (this.index_variant !== undefined && this.index_variant !== null) {
      $event['index_variant'] = this.index_variant;
    }

    if (this.code) {
      $event['code'] = this.code;
    }
    this.change.emit($event);
  }

  protected isImage(type: string) {
    return type && type.split('/')[0] === 'image';
  }

}
